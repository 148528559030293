import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { filter } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent implements OnInit{
  public currentUrl: string = '';
  public _dynamicDialogRef: DynamicDialogRef | undefined;

  constructor(
    public _route: Router,
    public _dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this._route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.urlAfterRedirects;
        }
      });
  }

  nevigateTo(type: string) {
    if (type === 'about') {
      if(this.currentUrl.includes('/booking')){
        this.openConfirmDialog('About Us');
      } else {
        this._route.navigate([`${RequestMapperService.CUSTOMER}/${RequestMapperService.ABOUT_ROUTE}`])
      }
    } else if (type === 'contact') {
      if(this.currentUrl.includes('/booking')){
        this.openConfirmDialog('Contact');
      } else {
        this._route.navigate([`${RequestMapperService.CUSTOMER}/${RequestMapperService.CONTACT_ROUTE}`])
      }
    } else if (type === 'service') {
      if(this.currentUrl.includes('/booking')){
        this.openConfirmDialog('Services');
      } else {
        this._route.navigate([`${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}`])
      }
    } else if (type === 'home') {
      if(this.currentUrl.includes('/booking')){
      this.openConfirmDialog('Home');
    } else {
      this._route.navigate([`${RequestMapperService.ROOT_URL}`])
    }
    }
  }

  public openConfirmDialog(type:any): void {
    this._dynamicDialogRef = this._dialogService.open(CommonDialogComponent, {
      data: {
        type: 'question',
        title: 'Your data will be lost when you redirect to another page, do you want to proceed?',
        // message: ' on the hours needed to clean your premises?',
        component: 'home',
        redirect:true
      },
      modal: true,
      resizable: true,
      width: '30rem',
      height: 'auto',
      showHeader:false,
      dismissableMask: true,
      contentStyle: {'overflow': 'auto', 'padding-top':'20px'},
      style:{overflow: 'hidden'},
      closable: true,
    });

    this._dynamicDialogRef.onClose.subscribe((result) => {
      if (result && result?.confirm) {
        switch (type){
          case 'About Us':
            this._route.navigate(
              [
                `${RequestMapperService.CUSTOMER}/${RequestMapperService.ABOUT_ROUTE}`,
              ]
            );
            break;
          case 'Services':
            this._route.navigate(
              [
                `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}`,
              ]
            );
            break;
          case 'Contact':
            this._route.navigate(
              [
                `${RequestMapperService.CUSTOMER}/${RequestMapperService.CONTACT_ROUTE}`,
              ]
            );
            break;
          case 'my-profile':
            this._route.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.PROFILE_ROUTE}`,
            ]);
            break;
          case 'my-service':
            this._route.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.MY_SERVICES}`,
            ]);
            break;
          default:
            this._route.navigate(
              [
                `${RequestMapperService.ROOT_URL}`,
              ],
            );
            break;
        }
      }
      this._dynamicDialogRef?.destroy();
    });
  }
}
