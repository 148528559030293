
@if (visible && dialogData.type == 'question') {
  <div class="flex flex-column align-items-center text-center  gap-3 dialog-open">
    <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/avatar/amyelsner.png" shape="circle" /> -->
    <i class="material-icons text-7xl" style="color: var(--bcd-btn);">help</i>

@if (dialogData?.redirect) {
  <h5 class="m-0 line-height-3">{{dialogData.title}}</h5>
}@else{

  <h4 class="m-0 line-height-3">{{dialogData.title}}</h4>
}
    <span class="m-0 text-lg">{{dialogData.message}}</span>
@if(dialogData.title === 'You are about to hire a professional cleaner at an hourly rate.'){
  <div class="flex gap-4 align-items-center justify-content-center ">
  <p-button [class.active]="isActive" [class.inactive]="!isActive"   (click)="onNo()" label="Yes" pAutoFocus [autofocus]="true" class="login-btn btn-no"></p-button>
  <p-button [class.active]="isActive" [class.inactive]="!isActive"  (click)="onYes()" label="Guide Me" pAutoFocus [autofocus]="true" class="login-btn btn-yes"></p-button>
</div>
}@else{
  <div class="flex gap-4 align-items-center justify-content-center ">
    <p-button [class.active]="isActive" [class.inactive]="!isActive"   (click)="onNo()" label="No" pAutoFocus [autofocus]="true" class="login-btn btn-no"></p-button>
  <p-button [class.active]="isActive" [class.inactive]="!isActive"  (click)="onYes()" label="Yes" pAutoFocus [autofocus]="true" class="login-btn btn-yes"></p-button>
</div>
}
  </div>
}

@if(visible && dialogData.type === 'submit'){
  <div class="flex flex-column align-items-center text-center  gap-3 dialog-open">
    <!-- <p-avatar image="https://primefaces.org/cdn/primeng/images/avatar/amyelsner.png" shape="circle" /> -->
    <i class="material-icons text-7xl"
    style="color: white; background-color: var(--bcd-btn);
    border-radius: 20rem;
    padding: 0.4rem;">check</i>


    <h4 class="m-0 line-height-3">{{dialogData.title}}</h4>
    <span class="m-0 text-lg">{{dialogData.message}}</span>

    <div class="flex gap-4 align-items-center justify-content-center ">
      <p-button [class.active]="isActive" [class.inactive]="!isActive"   (click)="onDone()" label="Done" pAutoFocus [autofocus]="true" class="login-btn"></p-button>
  </div>
  </div>
}

@if(visible && dialogData.type === 'cancelled'){
<div class="p-d-flex p-jc-center p-ai-center">
  <div class="p-pb-2">
    {{dialogData.title}}
  </div>
  <div class="p-d-flex p-jc-end">
    <button pButton type="button" label="No" icon="pi pi-times" class="p-mr-2" (click)="onNo()"></button>
    <button pButton type="button" label="Yes" icon="pi pi-check" (click)="onYes()"></button>
  </div>
</div>
}