<header class="header-section nav-section">
  <div
    class="top-bar container d-flex align-items-center justify-content-between"
  >
    <div class="flex flex-column lg:flex-row md:flex-row align-items-center">
      <div class="col-12 md:col-6 lg:col-6 pb-2 pl-0">
        <span class="flex align-items-center gap-2 text-base lg:pl-3 md:pl-3">
          <i class="material-icons svg-icon">mail</i>
          <a
            href="mailto:contact@cleanerstown.com"
            class="text-base"
            style="color: #fff"
            >contact&#64;cleanerstown.com</a
          >
        </span>
      </div>
      <div class="col-12 md:col-6 lg:col-6 pb-2 lg:pt-3 pt-2 pl-0">
        <p class="flex gap-3 lg:float-right md: float-right">
          <span class="flex align-items-center text-sm gap-2 sm:text-base">
            Helpline:
            <i class="material-icons svg-icon">call</i>
            (469) 230‑2208
          </span>

          <span class="flex align-items-center text-sm gap-2 sm:text-base">
            <i class="material-icons svg-icon">schedule</i>
            9:00 AM-5:00PM CST
          </span>
        </p>
      </div>
    </div>

    <div class="flex">
      <div class="nav-custom">
        <p-menubar
          [model]="isServiceProvider ? filteredItems : items"
          class="after-login"
        >
          <ng-template pTemplate="start">
            <img
              src="/assets/img/header_logo.svg"
              height="40"
              class=""
              alt="header"
              (click)="nevigateTo('home')"
            />
          </ng-template>

          @defer (when _common.isUserAuthenticated) { @if
          (_common.isUserAuthenticated) {
          <ng-container class="flex align-items-center h-full">
            <div class="flex lg:gap-4 gap-2">
              <!-- <button
                type="button"
                pButton
                (click)="showNotification()"
                style="display: none !important;"
                class="  notification-btn flex align-items-center "
                href=""
              >
                <i
                  class="pi pi-bell text-3xl material-icons"
                  pBadge
                  value="2"
                  severity="danger"
                  style="color: gray"
                >
                </i>
              </button> -->

              <div class="dropdown-section">
                <p-avatar
                  label="{{ formatNameInitials(userData?.name) | uppercase }}"
                  styleClass=" text-4xl font-medium"
                  size="xlarge"
                  [style]="{
                    'background-color': '#eef1fe',
                    color: '#4f72ee',
                    cursor: 'pointer'
                  }"
                  shape="circle"
                ></p-avatar>
                <span
                  class="flex flex-column text-base font-semibold line-height-3 ml-2 customer-data"
                  style="color: var(--text-color)"
                >
                  {{ userData?.name | titlecase }}
                  <small class="text-sm font-normal">{{
                    userData?.roleId === 1
                      ? "Admin"
                      : userData?.roleId === 3
                      ? "Customer"
                      : "Service Provider"
                  }}</small>
                </span>

                <p-button (click)="op.show($event, targetEl)">
                  <i class="material-icons" style="color: var(--bcd-btn)"
                    >arrow_drop_down</i
                  >
                </p-button>
                <div
                  #targetEl
                  class="mt-1 lg:mt-5 h-5rem flex align-items-center justify-content-center"
                >
                  <!-- <span>Target Element</span> -->
                </div>
                <p-overlayPanel #op>
                  <div class="">
                    <p
                      class="flex gap-3 p-3 align-items-center m-0"
                      style="border-bottom: 1px solid var(--bcd-border)"
                    >
                      <p-avatar
                        label="{{
                          formatNameInitials(userData?.name) | uppercase
                        }}"
                        styleClass=" text-4xl font-medium"
                        size="xlarge"
                        [style]="{
                          'background-color': '#eef1fe',
                          color: '#4f72ee',
                          cursor: 'pointer'
                        }"
                        shape="circle"
                      ></p-avatar>
                      <span class="flex flex-column gap-1 font-bold">
                        {{ userData?.name | titlecase }}
                        <small class="font-normal word-break">{{
                          userData?.email
                        }}</small>
                      </span>
                    </p>
                    <ul class="flex flex-column px-0 m-0 dropdown-list">
                      @if(userData?.roleId !== 1){
                      <li
                        class="flex gap-3"
                        (click)="profileClick('my-profile'); op.hide()"
                      >
                        <i class="material-icons">account_circle</i>
                        <span class="font-medium">My Profile</span>
                      </li>
                      } @if (userData?.roleId === 3) {
                      <li
                        class="flex gap-3"
                        (click)="profileClick('my-service'); op.hide()"
                      >
                        <i class="material-icons">assignment</i>
                        <span class="font-medium">My Service</span>
                      </li>
                      } @else if(userData?.roleId === 1) {
                      <li
                        class="flex gap-3"
                        (click)="profileClick('admin-dashboard'); op.hide()"
                      >
                        <i class="material-icons">assignment</i>
                        <span class="font-medium">My Dashboard</span>
                      </li>
                      }@else if(userData?.roleId === 2) {
                      <li
                        class="flex gap-3"
                        (click)="profileClick('my-dashboard'); op.hide()"
                      >
                        <i class="material-icons">assignment</i>
                        <span class="font-medium">My Dashboard</span>
                      </li>
                      }
                      <li
                        class="flex gap-3"
                        (click)="profileClick('My Log Out'); op.hide()"
                      >
                        <i class="material-icons">logout</i>
                        <span class="font-medium">Log Out</span>
                      </li>
                    </ul>
                  </div>
                </p-overlayPanel>
              </div>
            </div>
          </ng-container>
          } } @defer (when !_common.isUserAuthenticated) {
          <ng-container>
            <div class="flex gap-4">
              <a
                class="custom-btn hidden lg:block md:block"
                (click)="navigateToBookService()"
                >Book a Service</a
              >
              <a
                class="custom-btn"
                style="background: var(--primaryhignlight)"
                [routerLink]="['/auth/signin']"
              >
                <i class="material-icons svg-icon">login</i>

                Log In</a
              >
            </div>
          </ng-container>
          }
        </p-menubar>
      </div>
    </div>
  </div>
</header>
