<ngx-ui-loader></ngx-ui-loader>

@if (!currentUrl.includes('auth/') && !currentUrl.includes('/provider') &&
!currentUrl.includes('/admin')) {
<app-navbar></app-navbar>
}

<router-outlet></router-outlet>
<p-toast position="top-center" key="bc"></p-toast>

@if (!currentUrl.includes('auth/') && !currentUrl.includes('/provider') &&
!currentUrl.includes('/admin')){
<app-footer></app-footer>
}
