/* This configuration is to serve and deploy the app in the LOCALHOST environment */

export const environment = {
  production: false,
  staging: false,

  // SERVER_ENDPOINT: 'http://localhost:3001',
  SERVER_ENDPOINT: 'https://qa.cleanerstown.com/cleaner-backend',
  // NOTIFIER_ENDPOINT: 'http://localhost:3002',
  NOTIFIER_ENDPOINT: 'https://qa.cleanerstown.com/cleaner-notifier',
  // AUTH_ENDPOINT: 'http://localhost:3000',
  AUTH_ENDPOINT: 'https://qa.cleanerstown.com/cleaner-auth',
  // PAYMENT_ENDPOINT: 'http://localhost:3003',
  PAYMENT_ENDPOINT: 'https://qa.cleanerstown.com/cleaner-payment',
  SECRET_KEY: 'femi8crypt',

  //stripe public key
  // femi
  STRIPE_PK_KEY:
    'pk_test_51PNxx402aKmPXeI0zZYE7q9wUrAGAqQxn9RLHJQoFJVcKIDvNgJjhN1igfKfHyW5yKZ6LejM0eWfNN4sBdPPnvDo00vGPENI9Y',
  // STRIPE_PK_KEY: 'pk_live_51PNxx402aKmPXeI00wSuHofVqot3wetYRZST9QDyf2oe0TG36xLLAHnOrafdfm0ywebxdUwAYua1Hy00rmeHmmKi00meITdrU7'

  // bcd test
  // STRIPE_PK_KEY: 'pk_test_51O9PJcSBVPzT4HNlkxX4cxcZaZKGF8CiWQI7vzzYRzXJXbn3mmfQTPRe14JYZnUvbbAZWrj6NSRxSu1WiuyJkisY00Gw47RECR',
  // 'pk_live_51PNxx402aKmPXeI00wSuHofVqot3wetYRZST9QDyf2oe0TG36xLLAHnOrafdfm0ywebxdUwAYua1Hy00rmeHmmKi00meITdrU7',
};
