import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JWTService } from '../components/auth/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public serviceData: any[] = [];
  public serviceRequest: any={};
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public providerList: any[] = [];
  public serviceTimeInHours: number=0;
  public isUserAuthenticated: boolean = false;
  public userData: any = {};

  constructor(private _jwt: JWTService,) {
    this.isUserAuthenticated = this._jwt.hasValidJWT()
  }

  setData(data: any): void {
    this.dataSubject.next(data);
  }

  setUserData(data: any): void {
    this.userDataSubject.next(data);
  }

  // Get observable for data
  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }
  getUserData(): Observable<any> {
    return this.userDataSubject.asObservable();
  }
}
