import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class RequestMapperService {
  constructor() {}

  //URL for App routing default
  public static readonly BASE_RELATIVE_URL = '';
  public static readonly ROOT_URL: string = '/';
  public static readonly SIGNUP_URL: string = 'signup';
  public static readonly SIGNIN_URL: string = 'signin';
  public static readonly QA_LOGIN: string = 'qa-login';
  public static readonly OTP_ROUTE: string = 'otp';
  public static readonly ABOUT_ROUTE: string = 'about';
  public static readonly TERMS_ROUTE: string = 'terms';
  public static readonly SERVICE_ROUTE: string = 'service';
  public static readonly CONTACT_ROUTE: string = 'contact';
  public static readonly FORGOT_ROUTE: string = 'forget';
  public static readonly CONFIRMED_PASSWORD_ROUTE: string = 'confirm-pass';
  public static readonly AUTH: string = 'auth';
  public static readonly SERVICE_BOOK: string = 'booking';
  public static readonly GUIDED_BOOK: string = 'guided-booking';
  public static readonly SERVICE_PROVIDER: string = 'provider-list';
  public static readonly SERVICE_SUMMARY: string = 'summary';
  public static readonly CUSTOMER: string = 'customer';
  public static readonly PROFILE_ROUTE: string = 'profile';
  public static readonly MY_SERVICES: string = 'my-services';
  public static readonly PROVIDER_ORDER: string = 'provider-order';
  public static readonly PROVIDER: string = 'provider';
  public static readonly PROVIDER_ROUTE: string = 'profile';
  public static readonly ADMIN_ROUTE: string = 'admin';
  public static readonly ADMIN_PROVIDERS_ROUTE: string = 'providers';
  public static readonly ADMIN_CUSTOMERS_ROUTE: string = 'customers';
  public static readonly ADMIN_SERVICES_ROUTE: string = 'services';

  //URL for API endpoints
  public static readonly REGISTER_URL: string =
    environment.AUTH_ENDPOINT + '/auth/register';
  public static readonly LOGIN_URL: string =
    environment.AUTH_ENDPOINT + '/auth/login';
  public static readonly VERIFY_OTP: string =
    environment.AUTH_ENDPOINT + '/auth/verify-otp';
  public static readonly OTP_LOGIN: string =
    environment.AUTH_ENDPOINT + '/auth/login';
  public static readonly OTP_SEND: string =
    environment.AUTH_ENDPOINT + '/otp/generate/register';
  public static readonly OTP_VALIDATE: string =
    environment.AUTH_ENDPOINT + '/otp/validate/register';
  public static readonly GET_ZIP_CODE_DETAILS: string =
    environment.SERVER_ENDPOINT + '/postal';
  public static readonly RESET_OTP_SEND: string =
    environment.AUTH_ENDPOINT + '/otp/generate/reset-password';
  public static readonly RESET_OTP_VALIDATE: string =
    environment.AUTH_ENDPOINT + '/otp/validate/reset-password';
  public static readonly RESET_PASSWORD: string =
    environment.AUTH_ENDPOINT + '/auth/reset/password';
  public static readonly GET_SERVICES_DETAILS: string =
    environment.SERVER_ENDPOINT + '/service';
  public static readonly GET_ALL_PROVIDERS: string =
    environment.SERVER_ENDPOINT + '/provider/getAll';
  public static readonly SERVICE_REQUEST_RAISE: string =
    environment.SERVER_ENDPOINT + '/service/request/save';
  public static readonly GET_USER_DETAILS: string =
    environment.AUTH_ENDPOINT + '/auth/user';
  public static readonly GET_MY_SERVICES: string =
    environment.SERVER_ENDPOINT + '/service/request/get';
  public static readonly SAVE_USER_DETAILS: string =
    environment.AUTH_ENDPOINT + '/auth/user/update';
  public static readonly SERVICE_REQUEST_ACTION: string =
    environment.SERVER_ENDPOINT + '/service/request/action';
  public static readonly SERVICE_REQUEST_START: string =
    environment.SERVER_ENDPOINT + '/service/request/otp/generate';
  public static readonly SERVICE_REQUEST_STOP: string =
    environment.SERVER_ENDPOINT + '/service/request/otp/validate';
  public static readonly ADMIN_GET_USER_DETAILS: string =
    environment.SERVER_ENDPOINT + '/admin/users';
  public static readonly ADMIN_GET_SERVICE_DETAILS: string =
    environment.SERVER_ENDPOINT + '/admin/services';
  public static readonly PROVIDER_ACCOUNT_ADD: string =
    environment.PAYMENT_ENDPOINT + '/stripe/create/accounts';
  public static readonly PAYMENT: string =
    environment.PAYMENT_ENDPOINT + '/stripe/create/payment-intent';
  public static readonly RATING_SAVE: string =
    environment.SERVER_ENDPOINT + '/service/rating/save';
  public static readonly GET_ORDER_DETAIL: string =
    environment.SERVER_ENDPOINT + '/service/request/id';
  public static readonly CONTACT_US_SEND_EMAIL: string =
    environment.NOTIFIER_ENDPOINT + '/otp/send/mail';

  // message service defaults
  public static readonly SUCCESS: string = 'success';
  public static readonly WARN: string = 'warn';
  public static readonly ERROR: string = 'error';
  public static readonly INFO: string = 'info';

  // Notifier event type
  public static readonly OTP_FOR_REGISTER: string = 'OTP_FOR_REGISTER';

  public static getAbsoluteUrl(url: string): string {
    return RequestMapperService.ROOT_URL + url;
  }
}
