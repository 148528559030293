<footer>
  <div class="footer_section">
    <div class="container">
      <div class="grid align-items-center py-5">
        <div class="col-12 md:col-12 lg:col-2">
          <span class="footer_logo" (click)="nevigateTo('home')">
            <img
              src="/assets/img/footer_logo.svg"
              alt="footer_logo"
              height="45"
              srcset=""
            />
          </span>
        </div>
        <div class="col-12 md:col-12 lg:col-8">
          <div class="footer_nav">
            <ul class="flex p-0 align-items-center">
              <li
                class="nav-item"
                [class.active]="currentUrl === '/' || _route.url === '/'"
              >
                <a
                  class="nav-link"
                  aria-current="page"
                  (click)="nevigateTo('home')"
                  >Home</a
                >
              </li>
              <li
                class="nav-item"
                [class.active]="currentUrl.includes('/about')"
              >
                <a class="nav-link" (click)="nevigateTo('about')">About Us</a>
              </li>
              <li
                class="nav-item"
                [class.active]="
                  currentUrl.includes('/service') &&
                  !currentUrl.includes('/service/booking')
                "
              >
                <a class="nav-link" (click)="nevigateTo('service')">Services</a>
              </li>
              <li
                class="nav-item"
                [class.active]="currentUrl.includes('/contact')"
              >
                <a class="nav-link" (click)="nevigateTo('contact')">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 md:col-12 lg:col-2">
          <div class="social_link">
            <span>
              <a href="#"
                ><img src="/assets/img/facebook.png" alt="facebook"
              /></a>
              <a href="#"
                ><img src="/assets/img/twitter.png" alt="twitter"
              /></a>
              <a
                href="https://www.instagram.com/cleanertown/?igsh=cGk2MnJrbGcwODV1"
                target="_blank"
                rel="noreferrer"
                ><img src="/assets/img/instagram.png" alt="instagram"
              /></a>
            </span>
          </div>
        </div>
      </div>
      <div class="border-top w-full">
        <div class="container">
          <div class="grid align-items-center">
            <div class="col-12">
              <p
                class="flex justify-content-center pt-4 pb-3"
                style="color: var(--bcd-footerText)"
              >
                © CleanersTown 2024 All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
