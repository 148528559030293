import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { JWTService } from '../../auth/jwt.service';
import { UtilityService } from '../../../services/utility.service';
import { MessagesService } from '../../../services/message.service';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TransmissionService } from '../../../transmission/transmission.service';
import { DOMAIN } from '../../../transmission/domainTypes';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../../services/common.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MenubarModule,
    BadgeModule,
    AvatarModule,
    AvatarGroupModule,
    DropdownModule,
    FormsModule,
    SidebarModule,
    ButtonModule,
    OverlayPanelModule,
    CommonModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css',
  providers: [MessageService],
})
export class NavbarComponent implements OnInit {
  public _dynamicDialogRef: DynamicDialogRef | undefined;
  public items: MenuItem[] = [];
  public filteredItems: MenuItem[] = [];
  public selectedItem: { name: string } | undefined;
  public sidebarVisible2: boolean = false;
  public userData: any;
  isServiceProvider: boolean = false;
  activeMenuItem: any;

  constructor(
    private readonly _route: Router,
    private readonly messageService: MessageService,
    private readonly _jwt: JWTService,
    private readonly _util: UtilityService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly _transmit: TransmissionService,
    public readonly _common: CommonService,
    public readonly _dialogService: DialogService,
    private readonly _router: Router
  ) {
    this.items = [
      {
        label: 'Home',
        command: () =>
          this._router.url.includes('/booking')
            ? this.openConfirmDialog('Home')
            : this.nevigateTo('home'),
        id: '/',
        styleClass: this._router.url === '/' ? 'active' : '',
      },
      {
        label: 'About Us',
        command: () =>
          this._router.url.includes('/booking')
            ? this.openConfirmDialog('About Us')
            : this.nevigateTo('about'),
        id: '/customer/about',
        styleClass: this._router.url === '/customer/about' ? 'active' : '',
      },
      {
        label: 'Services',
        command: () =>
          this._router.url.includes('/booking')
            ? this.openConfirmDialog('Services')
            : this.nevigateTo('service'),
        id: '/customer/service',
        styleClass: this._router.url === '/customer/service' ? 'active' : '',
      },
      {
        label: 'Contact',
        command: () =>
          this._router.url.includes('/booking')
            ? this.openConfirmDialog('Contact')
            : this.nevigateTo('contact'),
        id: '/customer/contact',
        styleClass: this._router.url === '/customer/contact' ? 'active' : '',
      },
    ];
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeMenuItem = event.urlAfterRedirects;
        this.items = [
          {
            label: 'Home',
            command: () =>
              this.activeMenuItem.includes('/booking')
                ? this.openConfirmDialog('Home')
                : this.nevigateTo('home'),
            id: '/',
            styleClass: this.activeMenuItem === '/' ? 'active' : '',
          },
          {
            label: 'About Us',
            command: () =>
              this.activeMenuItem.includes('/booking')
                ? this.openConfirmDialog('About Us')
                : this.nevigateTo('about'),
            id: '/customer/about',
            styleClass:
              this.activeMenuItem === '/customer/about' ? 'active' : '',
          },
          {
            label: 'Services',
            command: () =>
              this.activeMenuItem.includes('/booking')
                ? this.openConfirmDialog('Services')
                : this.nevigateTo('service'),
            id: '/customer/service',
            styleClass:
              this.activeMenuItem === '/customer/service' ? 'active' : '',
          },
          {
            label: 'Contact',
            command: () =>
              this.activeMenuItem.includes('/booking')
                ? this.openConfirmDialog('Contact')
                : this.nevigateTo('contact'),
            id: '/customer/contact',
            styleClass:
              this.activeMenuItem === '/customer/contact' ? 'active' : '',
          },
        ];
        this.filteredItems = this.items.filter(
          (item) => item.id !== '/customer/service'
        );
      }
    });

    this._common.isUserAuthenticated = this._jwt.hasValidJWT();
    this.primengConfig.ripple = true;
  }

  ngOnInit(): void {
    this.filteredItems = this.items.filter(
      (item) => item.id !== '/customer/service'
    );
    if (this._common.isUserAuthenticated) {
      this.fetchUserDetails();
    }
    this._common.getUserData().subscribe({
      next: (data) => {
        if (data) {
          this.isServiceProvider = data.roleId == 2;
        }
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
      complete: () => {
        // Handle completion
      },
    });
  }

  // showNotification() {}
  async fetchUserDetails(): Promise<void> {
    await this._transmit
      .executeGetRequestPromise(
        `${RequestMapperService.GET_USER_DETAILS}`,
        DOMAIN.SERVER
      )
      .then((res: any) => {
        if (res) {
          this.userData = res?.data?.result;
          this._common.setUserData(this.userData);
        }
      })
      .catch((err: string) => {
        // this._util.showMessage(
        //   RequestMapperService.ERROR,
        //   MessagesService.MESSAGE_ERROR,
        //   err
        // );
      });
  }

  nevigateTo(type: string) {
    if (type === 'about') {
      this._route.navigate([
        `${RequestMapperService.CUSTOMER}/${RequestMapperService.ABOUT_ROUTE}`,
      ]);
    } else if (type === 'contact') {
      this._route.navigate([
        `${RequestMapperService.CUSTOMER}/${RequestMapperService.CONTACT_ROUTE}`,
      ]);
    } else if (type === 'service') {
      this._route.navigate([
        `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}`,
      ]);
    } else if (type === 'home') {
      this._route.navigate([RequestMapperService.ROOT_URL]);
    }
  }

  profileClick(event: any) {
    if (event === 'My Log Out') {
      this._jwt.destroySession();
      this.isServiceProvider = false;
      this._common.isUserAuthenticated = false;
      this._route
        .navigateByUrl(`${RequestMapperService.ROOT_URL}`)
        .then(() => {
          this._util.showMessage(
            RequestMapperService.SUCCESS,
            MessagesService.MESSAGE_SUCCESS,
            MessagesService.LOGOUT_SUCCESSFUL
          );
        })
        .catch((err: any) => {
          console.log(err.toString());
        });
    } else if (event === 'my-profile') {
      if (this.userData?.roleId === 3) {
        if (this.activeMenuItem?.includes('/booking')) {
          this.openConfirmDialog('my-profile');
        } else {
          this._route.navigate([
            `${RequestMapperService.CUSTOMER}/${RequestMapperService.PROFILE_ROUTE}`,
          ]);
        }
      } else if (this.userData?.roleId === 2) {
        this._route.navigate([
          `${RequestMapperService.PROVIDER}/${RequestMapperService.PROVIDER_ROUTE}`,
        ]);
      }
    } else if (event === 'my-service') {
      if (this.activeMenuItem?.includes('/booking')) {
        this.openConfirmDialog('my-service');
      } else {
        this._route.navigate([
          `${RequestMapperService.CUSTOMER}/${RequestMapperService.MY_SERVICES}`,
        ]);
      }
    } else if (event === 'my-dashboard') {
      this._router.navigate([
        `${RequestMapperService.PROVIDER}/${RequestMapperService.PROVIDER_ORDER}`,
      ]);
    } else if (event === 'admin-dashboard') {
      this._router.navigate(
        [
          `${RequestMapperService.ADMIN_ROUTE}/${RequestMapperService.ADMIN_SERVICES_ROUTE}`,
        ],
        { queryParams: { status: 'new' } }
      );
    }
  }

  public formatNameInitials(name: string | null): string {
    if (!name || name.trim() === '') {
      return '';
    }
    const words = name.trim().split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase();
    }
    const firstNameInitial = words[0].charAt(0).toUpperCase();
    const lastNameInitial = words[words.length - 1].charAt(0).toUpperCase();
    return firstNameInitial + lastNameInitial;
  }
  navigateToBookService() {
    this._router.navigate(
      [
        `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}/${RequestMapperService.SERVICE_BOOK}`,
      ],
      {
        queryParams: { guideline: false },
      }
    );
  }
  // public openDialog(): void {
  //   this._dynamicDialogRef = this._dialogService.open(CommonDialogComponent, {
  //     data: {
  //       type: 'question',
  //       title:
  //         'You are about to hire a professional cleaner at an hourly rate.' ||
  //         'Do you need guidelines',
  //       message:
  //         'Do you know how many hours needed for your cleaning service?' ||
  //         ' on the hours needed to clean your premises?',
  //       component: 'home',
  //     },
  //     modal: true,
  //     resizable: true,
  //     width: '30rem',
  //     height: 'auto',
  //     dismissableMask: true,
  //     showHeader: false,
  //     contentStyle: { overflow: 'auto', 'padding-top': '20px' },
  //     style: { overflow: 'hidden' },
  //     closable: true,
  //   });

  //   this._dynamicDialogRef.onClose.subscribe((result) => {
  //     if (result && result?.confirm) {
  //       this._router.navigate(
  //         [
  //           `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}/${RequestMapperService.SERVICE_BOOK}`,
  //         ],
  //         {
  //           queryParams: { guideline: true },
  //         }
  //       );
  //     } else if (result && !result?.confirm) {
  //       this._router.navigate(
  //         [
  //           `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}/${RequestMapperService.SERVICE_BOOK}`,
  //         ],
  //         {
  //           queryParams: { guideline: false },
  //         }
  //       );
  //     }
  //     this._dynamicDialogRef?.destroy();
  //   });
  // }
  public openConfirmDialog(type: any): void {
    this._dynamicDialogRef = this._dialogService.open(CommonDialogComponent, {
      data: {
        type: 'question',
        title:
          'Your data will be lost when you redirect to another page, do you want to proceed?',
        // message: ' on the hours needed to clean your premises?',
        component: 'home',
        redirect: true,
      },
      modal: true,
      resizable: true,
      width: '30rem',
      height: 'auto',
      showHeader: false,
      dismissableMask: true,
      contentStyle: { overflow: 'auto', 'padding-top': '20px' },
      style: { overflow: 'hidden' },
      closable: true,
    });

    this._dynamicDialogRef.onClose.subscribe((result) => {
      if (result?.confirm) {
        switch (type) {
          case 'About Us':
            this._router.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.ABOUT_ROUTE}`,
            ]);
            break;
          case 'Services':
            this._router.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}`,
            ]);
            break;
          case 'Contact':
            this._router.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.CONTACT_ROUTE}`,
            ]);
            break;
          case 'my-profile':
            this._route.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.PROFILE_ROUTE}`,
            ]);
            break;
          case 'my-service':
            this._route.navigate([
              `${RequestMapperService.CUSTOMER}/${RequestMapperService.MY_SERVICES}`,
            ]);
            break;
          default:
            this._router.navigate([`${RequestMapperService.ROOT_URL}`]);
            break;
        }
        this.items.forEach((item) => {
          if (item.label === type) {
            item['styleClass'] = '';
          }
        });
        this.items.forEach((item) => {
          if (item.id === this.activeMenuItem) {
            item['styleClass'] = 'active';
          }
        });
      }
      this._dynamicDialogRef?.destroy();
    });
  }
}
