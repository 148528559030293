import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
@Component({
  selector: 'app-common-dialog',
  standalone: true,
  imports: [ DialogModule, ButtonModule, AvatarModule],
  templateUrl: './common-dialog.component.html',
  styleUrl: './common-dialog.component.css'
})
export class CommonDialogComponent implements OnInit{

  public  visible: boolean = true;
  public dialogData:any;
  public isActive: boolean = false;

  constructor(
    private _dialogConfig: DynamicDialogConfig,
    private _dynamicDialogRef: DynamicDialogRef
  ) {}

  public ngOnInit(): void {
    this.dialogData = this._dialogConfig.data;
  }

  public onYes(): void{
    this.visible = false;
    this._dynamicDialogRef.close({confirm: true});
    this.isActive = !this.isActive;

  }
  public onNo(): void{
    this.visible = false;
    this._dynamicDialogRef.close({confirm: false});
    this.isActive = !this.isActive;
  }

  public onDone(): void{
    this.visible = false;
    this._dynamicDialogRef.close({done:true});
    this.isActive = !this.isActive;
  }




}
