import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom } from 'rxjs';
// import { LocalStorageService } from '../services/local-storage.service';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { ITransmissionResponse } from './interface/transmission-response';
import { JWTService } from '../components/auth/jwt.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService {

  constructor(
    private _http: HttpClient,
		private _httpErrorHandler: HttpErrorHandlerService,
		private _jwt: JWTService,
    private _loaderService: NgxUiLoaderService
  ) { }

  public executeGetRequest<T extends ITransmissionResponse>(
		url: string,
    domainType: number,
		params?: { [key: string]: any },
		requiresAuthentication: boolean = true
	) {
		return this._http.get<T>(url, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication),
			params
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		);
	}

  public async executeGetRequestPromise<T extends ITransmissionResponse>(
		url: string,
    domainType: number,
		params?: { [key: string]: any },
		requiresAuthentication: boolean = true
	) {

    this._loaderService.start()
		const result: any =  await firstValueFrom(this._http.get<T>(url, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication),
			params
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		));
    this._loaderService.stop();

    return result;
	}

  public executePostRequest<T>(
		url: string,
    domainType: number,
		payload: any,
		requiresAuthentication: boolean = true
	) {
		return this._http.post<ITransmissionResponse>(url, payload, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication)
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		);
	}

  public async executePostRequestPromise<T>(
		url: string,
    domainType: number,
		payload: any,
		requiresAuthentication: boolean = true
	) {

    this._loaderService.start();
		const result: any =  await firstValueFrom(this._http.post<ITransmissionResponse>(url, payload, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication)
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		));
    this._loaderService.stop();

    return result;
	}

  public executePatchRequest<T>(
		url: string,
    domainType: number,
		payload: any,
		requiresAuthentication: boolean = true
	) {
		return this._http.patch<ITransmissionResponse>(url, payload, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication)
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		);
	}

  public async executePatchRequestPromise<T>(
		url: string,
    domainType: number,
		payload: any,
		requiresAuthentication: boolean = true
	) {
    this._loaderService.start();
		const result: any =  await firstValueFrom(this._http.patch<ITransmissionResponse>(url, payload, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication)
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		));
    this._loaderService.stop();

    return result;
	}

  public executeDeleteRequest<T>(
		url: string,
    domainType: number,
		params?: { [key: string]: any },
		requiresAuthentication: boolean = true
	) {
		return this._http.delete<ITransmissionResponse>(url, {
			headers: this._jwt.injectToken(domainType, requiresAuthentication),
			params
		}).pipe(
			catchError((error) => this._httpErrorHandler.intercept(error))
		);
	}

}
