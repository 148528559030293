import { Injectable } from '@angular/core';
import { isEmptyString } from 'bmx-pastebox';
import { RequestMapperService } from './request-mapper.service';
import { AppComponent } from '../app.component';

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length!: number;
  clear(): void {}
  getItem(key: string): string | null {
    return null;
  }
  key(index: number): string | null {
    return null;
  }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage: Storage;
  [name: string]: any;
  private length: number | undefined;

  private readonly _KEY_AUTH_SUCCESS_ROUTE: string = 'cEfMKEcnhsUdxsfWtvJq';

  constructor() {
    this.storage = new LocalStorage();

    AppComponent.isBrowser.subscribe((isBrowser) => {
      if (isBrowser) {
        this.storage = localStorage;
      }
    });
  }

  // key(index: number): string | null {
  //   return this.storage.key(index);
  // }

  public store(key: string, value: string): void {
    // localStorage.setItem(key, value);
    this.storage.setItem(key, value);
  }

  public retrieve(key: string): string {
    // const value: string | null = localStorage.getItem(key);
    const value: string | null = this.storage.getItem(key);
    return value === null ? '' : value;
  }

  public hasKey(key: string): boolean {
    return this.retrieve(key) !== null;
  }

  public clear(): void {
    // localStorage.clear();
    this.storage.clear();
  }

  public removeItems(keys: string[]) {
    // keys.forEach((key: string, index: number) => localStorage.removeItem(key));
    keys.forEach((key: string, index: number) => this.storage.removeItem(key));
  }

  public storeOnAuthSuccessRoute(route: string): void {
    this.store(this._KEY_AUTH_SUCCESS_ROUTE, route);
  }

  public retrieveOnAuthSuccessRoute(): string {
    const route: string = this.retrieve(this._KEY_AUTH_SUCCESS_ROUTE);
    return isEmptyString(route) || route === '/auth/signin'
      ? RequestMapperService.ROOT_URL
      : route;
  }
}
