export function valueExists(value) {
  return !(typeof value === 'undefined' || value === null);
}
export function arrayNotEmpty(array) {
  return valueExists(array) && array.length > 0;
}
export function arrayEmpty(array) {
  return !arrayNotEmpty(array);
}
export function isEmptyString(str) {
  return valueExists(str) && (str.trim() === '' || str.trim().length === 0);
}
export function arrayLength(array, length) {
  return arrayNotEmpty(array) && array.length === length;
}
export function areStringsEqual(str1, str2, ignoreCase = false) {
  if (!valueExists(str1) || !valueExists(str2)) return false;else if (ignoreCase) return str1.toLowerCase() === str2.toLowerCase();else return str1 === str2;
}
// https://stackoverflow.com/a/23481096/3640307
export function arrayDeepCopy(array) {
  return JSON.parse(JSON.stringify(array));
}
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
// https://stackoverflow.com/a/10727155/3640307
export function alphaNumericID(length = 12) {
  const charSet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += charSet[Math.floor(Math.random() * charSet.length)];
  return result;
}