import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { MenubarModule } from 'primeng/menubar';
import { FooterComponent } from './components/shared/footer/footer.component';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
// import { BrowserModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BehaviorSubject, filter } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    FormsModule,
    PasswordModule,
    NavbarComponent,
    MenubarModule,
    FooterComponent,
    BadgeModule,
    AvatarModule,
    // BrowserModule,
    // BrowserAnimationsModule
    ToastModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    HttpClientModule
  ],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})

export class AppComponent implements OnInit {
  title = 'cleaner-frontend';
  public currentUrl: string = '';
  static readonly isBrowser = new BehaviorSubject<boolean>(false);

  constructor(private _router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    }

  ngOnInit(): void {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.urlAfterRedirects;
        }
      });
  }
}
